import { useState } from "react"
import styled from "styled-components"

import { useQueryClient } from "@tanstack/react-query"

import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import {
  StepInvitations,
  TSelectedInvitation,
} from "src/components/Onboarding/JoinOrganization/StepInvitations"
import { StepJoinOrgSuccess } from "src/components/Onboarding/JoinOrganization/StepJoinOrgSuccess"
import { useGetInvitations } from "src/components/Onboarding/JoinOrganization/useGetInvitations"
import { OnboardingCurrentUser } from "src/components/Onboarding/OnboardingCurrentUser"
import {
  ORG_ROLE_FORM_ID,
  OrganizationRoleStep,
  TRoleRadioButton,
} from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationRoleStep/OrganizationRoleStep"
import { useOrganizationRoleForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationRoleStep/useOrganizationRoleForm"
import { breakpoint } from "src/constants/breakpoints"
import { useAppData } from "src/context/useAppData"
import { usePostIdentify } from "src/data/analytics/queries/analyticsQueries"
import { usePatchOrganizationInvite } from "src/data/organizations/queries/inviteQueries"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import { TOrganizationInvitation } from "src/data/organizations/types/organizationTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { backgroundGrayV3 } from "src/ui/colors"
import backdropImage from "src/ui/images/photo-luxury-seaside.jpg"
import backdropImagePortrait from "src/ui/images/photo-luxury-seaside-portrait.jpg"
import { MCard } from "src/ui/MCard/MCard"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"
import { InlineWizard } from "src/ui/Wizard/InlineWizard"
import { ButtonDirection, IWizardStep } from "src/ui/Wizard/wizardTypes"
import { debug } from "src/utils/logger"

export function JoinOrganizationWizard() {
  const { setActiveOrgId } = useAppData()

  const [currentStep, setCurrentStep] = useState(0)
  const [joinedOrg, setJoinedOrg] = useState<{ name: string; id: string }>()
  const [selectedInvitation, setSelectedInvitation] =
    useState<TSelectedInvitation>()

  const { invitationsLoading, errorMsg, invitations } = useGetInvitations()

  const postIdentify = usePostIdentify()
  const patchOrganizationInvite = usePatchOrganizationInvite()
  const queryClient = useQueryClient()

  const { goBack } = useRouter()
  const { t, langKeys } = useTranslate()

  const userRoleForm = useOrganizationRoleForm({
    initialValues: {
      profilingRole: null,
      customProfilingRole: "",
    },
    onSubmit: () => {
      handleAcceptInvitation()
    },
  })

  function handleAcceptInvitation() {
    if (!selectedInvitation?.code) {
      return
    }

    if (userRoleForm.values.profilingRole) {
      postIdentify.mutate({
        profiling_role: userRoleForm.values.profilingRole,
        custom_profiling_role: userRoleForm.values.customProfilingRole,
      })
    }

    patchOrganizationInvite.mutate(
      { inviteCode: selectedInvitation.code },
      {
        onSuccess: handleInviteAcceptSuccess,
        onError: (e) => debug.log("Accept invitation error", e),
      }
    )
  }

  async function handleInviteAcceptSuccess(
    acceptedInvite: TOrganizationInvitation
  ) {
    debug.log("Accept invite successfull", acceptedInvite.organization_id)

    setJoinedOrg({
      id: acceptedInvite.organization_id,
      name: acceptedInvite.invitation_target_name,
    })
    setActiveOrgId(acceptedInvite.organization_id)
    // We need to invalidate the query here to make sure that activeOrg is always
    // refeteched, otherwise the user may end up in scenarios where AppData
    // is loading indefinitely.
    await queryClient.invalidateQueries(orgsKeys.activeOrg())

    setCurrentStep((currStep) => currStep + 1)
  }

  const steps: IWizardStep[] = [
    {
      component: invitationsLoading ? (
        <StyledCircularProgress color="inherit" size={48} />
      ) : (
        <StepInvitations
          invitations={invitations}
          selectedInvitation={selectedInvitation}
          onSelectInvitation={setSelectedInvitation}
          errorMsg={errorMsg}
        />
      ),
      backButtonLabel: t(langKeys.close),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled: !invitations.length || invitationsLoading || !!errorMsg,
        loading: patchOrganizationInvite.isLoading,
      },
      onBack: () => goBack({ defaultPath: Routes.Dashboard.location() }),
      footer: <OnboardingCurrentUser />,
    },
    {
      component: (
        <OrganizationRoleStep
          formData={userRoleForm}
          isError={patchOrganizationInvite.isError}
          options={getOrgUserRoleOptions(t)}
        />
      ),
      nextButtonLabel: t(langKeys.onboarding_organization_accept_invitation),
      nextButtonProps: {
        form: ORG_ROLE_FORM_ID,
        loading: patchOrganizationInvite.isLoading,
      },
    },
    {
      component: <StepJoinOrgSuccess joinedOrg={joinedOrg} />,
      hideBackButton: true,
      direction: ButtonDirection.COLUMN,
      onNext: () => goBack({ defaultPath: Routes.Dashboard.location() }),
      nextButtonLabel: t(langKeys.organizations_team_join_success_button_label),
      hideProgress: true,
    },
  ]

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
      backgroundColor={backgroundGrayV3}
    >
      <InvitationsCard>
        <InlineWizard
          currentStep={currentStep}
          steps={steps}
          onNext={() => setCurrentStep((currStep) => currStep + 1)}
          onBack={() => setCurrentStep((currStep) => currStep - 1)}
        />
      </InvitationsCard>
    </ImageBackdrop>
  )
}

const InvitationsCard = styled(MCard)`
  padding: ${spacing.L};
  max-width: 65ch;

  @media (${breakpoint.mediumUp}) {
    padding: ${spacing.XL2};
  }
`

const StyledCircularProgress = styled(MCircularProgress)`
  display: flex;
  margin: ${spacing.M} auto;
`
function getOrgUserRoleOptions(t: TTranslateFunction): {
  left: TRoleRadioButton[]
  right: TRoleRadioButton[]
} {
  return {
    left: [
      {
        value: "Host/Owner",
        label: t(langKeys.create_organization_role_host_owner),
      },
      {
        value: "CEO/VP (C-suite)",
        label: t(langKeys.create_organization_role_ceo_vp),
      },
      {
        value: "Regional Manager",
        label: t(langKeys.create_organization_role_regional_manager),
      },
      {
        value: "City/Site Manager",
        label: t(langKeys.create_organization_role_city_site_manager),
      },
      {
        value: "Guest Communications/Frontdesk",
        label: t(
          langKeys.create_organization_service_role_guest_communications_frontdesk
        ),
      },
    ],
    right: [
      {
        value: "Maintenance",
        label: t(langKeys.join_organization_role_maintenance),
      },
      {
        value: "Security",
        label: t(langKeys.join_organization_role_security),
      },
      {
        value: "Finance",
        label: t(langKeys.join_organization_role_finance),
      },
      {
        value: "Other",
        label: t(langKeys.create_organization_role_other),
      },
    ],
  }
}
